import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { AlertService } from 'src/app/services/alert.service';
import { ArboService } from 'src/app/services/arbo.service';
import { HttpService } from 'src/app/services/http.service';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService implements CanActivate {
  public menuIndex = 0;
  public user;
  public sectionId = 1;
  public membership;
  public loaded;
  public accountId;
  public currentSourceId;

  private _resolve;

  // private flowStatus = {
  //   accountId: 1,
  //   campaignEid: 0,
  //   sourceId: 0,
  //   postId: 0,
  //   currentStep: 0,
  // };


  constructor(
    private router: Router,
    private alertService: AlertService,
    private arboService: ArboService,
    private userService: UserService,
    private storageService: StorageService,
    private httpService: HttpService
  ) {
    this.loaded = new Promise((resolve) => {
      this._resolve = resolve;
    });
    this.loadPreviousSession();
  }

  async loadPreviousSession() {
    this.user = this.storageService.get('user');
    if (this.user && this.user.token) await this.setupSession(this.user.token);
  }

  async setupSession(token) {
    this.httpService.setKey(token.token);
    const user = await this.arboService.get('User', token.userId);
    user.token = token;
    try {
      user.memberships = await this.userService.getMemberships();
    } catch (e) {
      user.memberships = [];
    }
    this.setUser(user);
    try {
      this.membership = this.storageService.get('membership');
    } catch (e) {}
    if (this.membership) await this.selectMembership(this.membership);
    else if (user.memberships.length > 0)
      await this.selectMembership(user.memberships[0]);
    this._resolve(this.user);
  }

  async selectMembership(membership) {
    this.membership = membership;
    await this.storageService.store('membership', this.membership);
    if (membership) this.sectionId = membership.sectionId;
  }

  async selectMembershipBySectionId(sectionId) {
    await this.selectMembership(
      this.user.memberships.find((membership) => {
        return membership.sectionId == sectionId;
      })
    );
  }

  setUser(user) {
    this.user = user;
    return this.storageService.store('user', this.user);
  }

  getUser() {
    return this.user;
  }

  saveUser(data) {
    if (this.user) {
      data = Object.assign(this.user, data);
    } else {
      // TODO: remover
      data.id = 1;
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(data);
      }, 1500);
    }).then((user) => {
      this.setUser(user);
      return this.user;
    });
  }

  async login(login, password) {
    this.httpService.setKey(null);
    this.setUser(null);
    const token = await this.arboService.auth(login, password);
    await this.setupSession(token);
  }

  async logout() {
    if (this.getUser()) {
      const msg = 'Até mais, ' + this.getUser().profile.nome + '!';
      this.alertService.toast(msg);
    }
    this.httpService.setKey(null);
    this.setUser(null);
    this.selectMembership(null);
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (state.url.substr(0, 6) == '/login') {
      if (this.getUser()) {
        this.logout();
      }
    } else {
      if (!this.getUser()) {
        await this.router.navigate(['/login']);
        return false;
      }
    }
    return true;
  }
}
